import { useEffect, useState } from "react";
import { fetchData } from "helpers/functions/fetchData";
import { useLoader } from "providers/LoaderProvider";
import { useLocale } from "providers/LocaleProvider";
import { Link, useParams } from "react-router-dom";
import Button from "components/Button";
import { getFormattedTimeDifference } from "helpers/functions/getFormattedTimeDifference";
import JobApplicationForm from "../JobApplicationForm";
import { SCompJobDetails } from "./styles/SCompJobDetails";
import { SCompJobDetailsDate } from "./styles/SCompJobDetailsDate";
import { SCompJobDetailsHero } from "./styles/SCompJobDetailsHero";
import { SCompJobDetailsHint } from "./styles/SCompJobDetailsHint";
import { SCompJobDetailsLocation } from "./styles/SCompJobDetailsLocation";
import { SCompJobDetailsSpecs } from "./styles/SCompJobDetailsSpecs";
import { SCompJobDetailsSpecsItem } from "./styles/SCompJobDetailsSpecsItem";
import { SCompJobDetailsSpecsItemText } from "./styles/SCompJobDetailsSpecsItemText";
import { SCompJobDetailsSpecsItemTitle } from "./styles/SCompJobDetailsSpecsItemTitle";
import { SCompJobDetailsText } from "./styles/SCompJobDetailsText";
import { SCompJobDetailsTitle } from "./styles/SCompJobDetailsTitle";
import { SCompJobDetailsContainer } from "./styles/SCompJobDetailsContainer";
import { SCompJobDetailsTitleAndBtn } from "./styles/SCompJobDetailsTitleAndBtn";
import { SCompJobDetailsTitleAndDate } from "./styles/SCompJobDetailsTitleAndDate";
import { SCompJobDetailsTop } from "./styles/SCompJobDetailsTop";

const CompJobDetails = () => {
  const [data, setData] = useState();
  const { openLoader, closeLoader } = useLoader();
  const { locale, isArabic } = useLocale();
  const { id } = useParams();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData("/jobs/", locale, `&include=${id}`);
      setData(response[0]);
      closeLoader();
    } catch {
      closeLoader();
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, [locale]);

  if (!data) return <></>;

  return (
    <SCompJobDetails className="company-job-details">
      <SCompJobDetailsHero isArabic={isArabic}>
        <Link to={`/${locale}/careers`}>
          <p>{isArabic ? "العودة إلي الوظائف" : "Return To Careers"}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="8" viewBox="0 0 37 8" fill="none">
            <path
              d="M0.646446 3.64644C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976308 4.7308 0.659725 4.53553 0.464463C4.34027 0.269201 4.02369 0.269201 3.82843 0.464463L0.646446 3.64644ZM37 3.5L1 3.5L1 4.5L37 4.5L37 3.5Z"
              fill="#3F3F3F"
            />
          </svg>
        </Link>
      </SCompJobDetailsHero>
      <SCompJobDetailsContainer isArabic={isArabic}>
        <SCompJobDetailsTitleAndBtn>
          <SCompJobDetailsTop>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="47" height="47" rx="4" fill="#EBEBEB" />
              <g clipPath="url(#clip0_251_447)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26 16C27.6569 16 29 17.3432 29 19H32C33.1046 19 34 19.8954 34 21V31C34 32.1046 33.1046 33 32 33H16C14.8954 33 14 32.1046 14 31V21C14 19.8954 14.8954 19 16 19H19C19 17.3432 20.3432 16 22 16H26ZM27 21H21V31H27V21ZM32 21H29V31H32V21ZM19 21H16V31H19V21ZM26 18H22C21.4872 18 21.0645 18.386 21.0067 18.8834L21 19H27C27 18.4872 26.614 18.0645 26.1166 18.0067L26 18Z"
                  fill="#C6C6C6"
                />
              </g>
              <defs>
                <clipPath id="clip0_251_447">
                  <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                </clipPath>
              </defs>
            </svg>
            <SCompJobDetailsTitleAndDate isArabic={isArabic}>
              {data.acf?.location && <SCompJobDetailsLocation>{data.acf.location}</SCompJobDetailsLocation>}
              <SCompJobDetailsTitle>{data.title.rendered}</SCompJobDetailsTitle>
              <SCompJobDetailsDate>{getFormattedTimeDifference(data.date, isArabic)}</SCompJobDetailsDate>
            </SCompJobDetailsTitleAndDate>
          </SCompJobDetailsTop>
          <Button
            as="button"
            onClick={() => {
              setIsModalOpened(true);
            }}
          >
            {isArabic ? "التقدم للوظيفة" : "Apply for the job"}
          </Button>
        </SCompJobDetailsTitleAndBtn>
        <SCompJobDetailsSpecs>
          {data.acf?.job_type && (
            <SCompJobDetailsSpecsItem>
              <SCompJobDetailsSpecsItemTitle>{isArabic ? "نوع الوظيفة" : "Job Type"}</SCompJobDetailsSpecsItemTitle>
              <SCompJobDetailsSpecsItemText>{data.acf?.job_type}</SCompJobDetailsSpecsItemText>
            </SCompJobDetailsSpecsItem>
          )}
          {data.acf?.work_type && (
            <SCompJobDetailsSpecsItem>
              <SCompJobDetailsSpecsItemTitle>{isArabic ? "نوع العمل" : "Work Type"}</SCompJobDetailsSpecsItemTitle>
              <SCompJobDetailsSpecsItemText>{data.acf.work_type}</SCompJobDetailsSpecsItemText>
            </SCompJobDetailsSpecsItem>
          )}
          {data.acf?.location && (
            <SCompJobDetailsSpecsItem>
              <SCompJobDetailsSpecsItemTitle>{isArabic ? "الموقع" : "Location"}</SCompJobDetailsSpecsItemTitle>
              <SCompJobDetailsSpecsItemText>{data.acf.location}</SCompJobDetailsSpecsItemText>
            </SCompJobDetailsSpecsItem>
          )}

          {data.acf?.career_level && (
            <SCompJobDetailsSpecsItem>
              <SCompJobDetailsSpecsItemTitle>
                {isArabic ? "المستوى الوظيفي" : "Career Level"}
              </SCompJobDetailsSpecsItemTitle>
              <SCompJobDetailsSpecsItemText>{data.acf.career_level}</SCompJobDetailsSpecsItemText>
            </SCompJobDetailsSpecsItem>
          )}
          {data.acf?.years_of_experience !== (null || undefined) ? (
            <SCompJobDetailsSpecsItem>
              <SCompJobDetailsSpecsItemTitle>
                {isArabic ? "سنوات الخبرة" : "Years of experience"}
              </SCompJobDetailsSpecsItemTitle>
              <SCompJobDetailsSpecsItemText>{data.acf.years_of_experience}</SCompJobDetailsSpecsItemText>
            </SCompJobDetailsSpecsItem>
          ) : (
            ""
          )}
          {data.acf?.number_of_vacancies && (
            <SCompJobDetailsSpecsItem>
              <SCompJobDetailsSpecsItemTitle>
                {isArabic ? "عدد الوظائف الشاغرة" : "Number of vacancies"}
              </SCompJobDetailsSpecsItemTitle>
              <SCompJobDetailsSpecsItemText>{data.acf.number_of_vacancies}</SCompJobDetailsSpecsItemText>
            </SCompJobDetailsSpecsItem>
          )}
        </SCompJobDetailsSpecs>
        <SCompJobDetailsText isArabic={isArabic} dangerouslySetInnerHTML={{ __html: data?.content?.rendered }} />
        <SCompJobDetailsHint>
          {isArabic
            ? "برجاء التحقق جيدا من الوظيفة قبل التقديم"
            : "Please check the position carefully before applying"}
        </SCompJobDetailsHint>
        <Button
          as="button"
          onClick={() => {
            setIsModalOpened(true);
          }}
        >
          {isArabic ? "التقدم للوظيفة" : "Apply for the job"}
        </Button>
      </SCompJobDetailsContainer>
      <JobApplicationForm isOpen={isModalOpened} setIsOpen={setIsModalOpened} />
    </SCompJobDetails>
  );
};

export default CompJobDetails;
